import { Component, ViewChild } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';

@Component({
    templateUrl: 'fullscreen-layout.component.html',
})
export class FullscreenLayoutComponent {

    get navbar(): NavbarComponent {
        return this.navbarComponent;
    }

    @ViewChild(NavbarComponent, { static: true }) private navbarComponent: NavbarComponent;
}
