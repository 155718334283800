import { publishReplay, refCount } from 'rxjs/operators';
import { BaseRequestService } from './base-request.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EntityNameBean, IngredientBean, MenuItemState, PageBean, ResponseBean, ResponseItemBean } from '../model/model';

@Injectable()
export class IngredientService extends BaseRequestService {

    private ingredients: Observable<any>;
    private ingredientNames: Observable<any>;

    updateState(state: MenuItemState, ids: number[]): Observable<ResponseItemBean<EntityNameBean[]>> {
        return this.put('admin/ingredient/state', { state, ids });
    }

    getIngredients(useCache: boolean = true, limit?: number, offset?: number): Observable<PageBean<IngredientBean>> {
        if (!useCache || !this.ingredients) {
            this.ingredients = this.get('admin/ingredient/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.ingredients;
    }

    getIngredientNames(limit?: number, offset?: number): Observable<ResponseItemBean<EntityNameBean[]>> {
        if (!this.ingredientNames) {
            this.ingredientNames = this.get('admin/ingredient/names', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.ingredientNames;
    }

    addIngredient(ingredient: IngredientBean): Observable<ResponseItemBean<IngredientBean>> {
        this.ingredients = null;
        return this.post('admin/ingredient/add', ingredient);
    }

    updateIngredient(ingredient: IngredientBean): Observable<ResponseBean> {
        this.ingredients = null;
        return this.put('admin/ingredient/' + ingredient.id + '/update', ingredient);
    }

    deleteIngredient(ingredient: IngredientBean): Observable<ResponseBean> {
        this.ingredients = null;
        return this.del('admin/ingredient/' + ingredient.id + '/delete');
    }

    clear() {
        this.ingredients = null;
    }
}
